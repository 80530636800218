.view-details-container {
    .view-overview-container {
        .card {
            border-radius: 20px;

            .card-body.default-setng {
                padding: 1rem 1rem 0;

                .right-side {
                    position: absolute;
                    right: 0;
                    top: 0;

                    span {
                        &.right-side-icon {
                            // background: #eee;
                            font-size: 30px;
                            border-radius: 100%;
                            float: left;
                            width: 50px;
                            height: 50px;
                            display: block;
                            margin-top: 5px;
                            text-align: center;

                            .mdi {
                                position: relative;
                                text-align: center;
                                display: block;
                                // top: -2px;
                                margin-top: 4px;

                                .badge {
                                    height: 17px;
                                    width: 17px;
                                    border-radius: 10px;
                                    font-size: 12px;
                                    position: absolute;
                                    right: 3px;
                                    bottom: 0px;
                                    color: rgb(107, 107, 107);
                                    display: inline-flex;
                                    align-items: center;
                                }
                            }

                            .mdi-export {
                                color: rgb(107, 107, 107);
                            }

                            .mdi-book-variant {
                                color: #59a5d8;
                            }
                        }
                    }
                }
            }
        }
    }

    .row {
        &.inner-details-page {
            .card {
                &.product-card {
                    // height:239px;
                    overflow: auto;

                    .card-body {
                        .btn-display {
                            display: flex;
                            flex-wrap: wrap;

                            .btn {
                                margin-top: 5px;
                            }

                            .item {
                                cursor: pointer;
                                font-size: 1rem;

                                .content {
                                    color: #000;

                                    &.active {
                                        color: #0096c7;
                                    }
                                }

                                .content:hover {
                                    color: #0096c7;
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }

                .card-body {
                    .detail-heading {
                        font-size: 20px;
                        font-weight: 400;
                    }

                    .detail {
                        padding: 5px 0;
                        display: block;
                        color: rgb(85, 85, 85);
                    }

                    .header {
                        font-weight: bolder;
                        color: #429a86;
                    }
                }
            }
        }

        &.prdouct-lst-dtl {
            .product-overview-container {
                .card {
                    .card-body {
                        .row {
                            margin-top: 45px;

                            span {
                                &.title-prdct {
                                    font-size: 20px;
                                    display: block;
                                    text-transform: capitalize;
                                    font-weight: 500;
                                    // text-decoration: underline;
                                    margin-bottom: 20px;
                                    // margin-left: -30px;
                                    // margin-right: -30px;
                                    color: #343a40;
                                    border-bottom: 1px solid #cacaca;
                                    font-family: "Roboto", sans-serif;
                                }

                                &.loader-spinner {
                                    height: 175px;

                                    .spinner-wrapper {
                                        top: 0;
                                        left: 0;
                                        margin: auto;
                                        right: 0;
                                        position: absolute;
                                        bottom: 0;
                                        height: 175px;
                                    }
                                }
                            }

                            .list-prd-detl {
                                // width: 400px;
                                float: left;
                                padding: 20px 60px;
                                background-color: #caf0f8;
                                border-radius: 20px;
                                margin: 0px 20px;
                                min-width: 10%;

                                .list-prd-detl {
                                    float: left;
                                    margin: 0;
                                    float: left;
                                    padding: 25px 0;
                                }

                                .prodct-key {
                                    padding: 5px 0;
                                    display: block;

                                    .product-key-title {
                                        font-size: 16px;
                                        font-weight: 550;
                                        color: #343a40;
                                        text-transform: capitalize;
                                    }

                                    .product-value-title {
                                        font-size: 16px;
                                        font-weight: 400;
                                        color: #343a40;

                                        .mdi-close {
                                            color: #e3001e;
                                        }

                                        .mdi-check {
                                            color: #03be07;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.td-r2 {
    height: 18rem;
}

.td-r3 {
    height: 450px;
}

.pulsate-misconfig {
    border-radius: 50%;
    color: #ffb700;
    box-shadow: 0 0 0 0 rgb(0, 0, 0);
    transform: scale(1);
    animation: pulse1 2s infinite;
    cursor: pointer;
}

@keyframes pulse1 {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 128, 0, 0.7);
    }

    70% {
        box-shadow: 0 0 0 10px rgba(255, 128, 0, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(255, 128, 0, 0);
    }
}

.pulsate-healthy {
    border-radius: 50%;
    color: #2b9348;
    animation: pulse 2s infinite ease-in-out;
}

@keyframes pulse {
    0% {
        transform: scale(0.9);
    }

    25% {
        transform: scale(0.95);
    }

    50% {
        transform: scale(1);
    }

    75% {
        transform: scale(0.95);
    }

    100% {
        transform: scale(0.9);
    }
}

.pulsate-alerts {
    border-radius: 50%;
    color: #2b9348;
    animation: pulse-alert 1s infinite ease-in-out;
}

@keyframes pulse-alert {
    0% {
        box-shadow: 0 0 0 0 rgba(179, 5, 5, 0.7);
    }

    70% {
        box-shadow: 0 0 0 20px rgba(179, 5, 5, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(179, 5, 5, 0);
    }
}

.pulsate-unhealthy {
    border-radius: 50%;
    color: #e71d36;
    box-shadow: 0 0 0 0 rgb(0, 0, 0);
    transform: scale(1);
    animation: pulse2 2s infinite;
    cursor: pointer;
}

@keyframes pulse2 {
    0% {
        box-shadow: 0 0 0 0 rgba(179, 5, 5, 0.7);
    }

    70% {
        box-shadow: 0 0 0 10px rgba(179, 5, 5, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(179, 5, 5, 0);
    }
}

.pulsate-expiry {
    border-radius: 50%;
    color: red;
    box-shadow: 0 0 0 0 rgb(0, 0, 0);
    transform: scale(1);
    animation: pulse3 2s infinite;
}

#link-icon {
    width: 1.4rem;
}

#grafana-img {
    width: 1.7rem;
}

#logs-img {
    width: 2.5rem;
}

#custom-icon {
    margin-top: -0.3rem;
}

#envCount {
    border: solid #429a86;
    height: 2rem;
    margin-top: 0.9rem;
    background: #429a86;
    color: white;
    font-size: 1.6rem;
    border-radius: 20%;
}

@keyframes pulse3 {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 115, 0, 0.7);
    }

    70% {
        box-shadow: 0 0 0 10px rgba(255, 153, 0, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(255, 153, 0, 0);
    }
}

.timer-shake {
    transform: scale(1);
    animation: shake 0.3s infinite;
}

@keyframes shake {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(5deg);
    }

    50% {
        transform: rotate(0eg);
    }

    75% {
        transform: rotate(-5deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.waring-on-hover {
    position: absolute;
    width: 270px;
    min-height: 60px;
    display: block;
    background: #fff;
    z-index: 99999;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.5s ease-in-out;
    border-radius: 20px;
    top: 60px;
    left: 17px;

    ul {
        display: block;
        margin: 25px 25px;

        li {
            &.item {
                font-size: 15px;

                &:hover {
                    background-color: #f1f1f1;
                }

                .content {
                    font-size: 15px;
                }

                .description {
                    font-size: 13px;
                    color: #666;
                }

                // .content:hover {
                //     color: lavender;
                // }
            }
        }
    }
}

.health-container {
    .infra-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px 10px;
        margin: 10px;
    }

    .infra-item {
        height: 35px;
        padding: 12px;
        border-radius: 10px;
        background-color: #90da81;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #f1faee;
        font-size: 15px;
        font-weight: 400;

        &.error {
            background-color: #ff5c64
        }
    }
}

.mdi-lightbulb {
    color: #ffb703;
    cursor: pointer;
}

.mdi-lightbulb-outline {
    margin-top: -3px;
    color: #343a40;
}

.animate-bulb {
    border-radius: 50%;
    background-color: transparent;
    animation: bulbLightUp 3s ease-in-out infinite;
}

@keyframes bulbLightUp {
    50% {
        text-shadow: 0px -5px 18px #ffb803;
    }
}

.separator {
    border-left: 2px solid #b7b7b7;
    border-radius: 0px !important;
    height: 33px !important;
    margin-top: 13px !important;
    margin-right: -2rem;
}